import React from 'react'
import ReactDOM from 'react-dom/client'
import L from '@loadable/component'
import { unregister } from './service/worker'

const fallback = <div style={{ height: '100%', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <img src={process.env.PUBLIC_URL + '/static/app-loading.svg'} alt={'img-loading'}/>
</div>

const App = L(() => import(/* webpackChunkName: "app" */'./app'), { fallback })

const root = document.getElementById('root')
if (root) ReactDOM.createRoot(root).render(<App/>)

unregister()
